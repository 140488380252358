import { CChart } from "@coreui/react-chartjs"
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil"
import { priceInfoState } from "../../features/priceinfo/state";

export const PriceInfoChart = () => {
  const prices = useRecoilValue(priceInfoState);
  const [barState, setBarState] = useState<any>();

  useEffect(() => {
    const data = [
      {
        type: 'bar' as const,
        label: 'Today',
        data: prices?.todayPrices.map(c =>  c.total) as number[],
        borderColor: 'rgb(250,164,58)',
        backgroundColor: 'rgba(0,255,180,1)',
      }
    ];

    if (prices?.tomorrowPrices.length) {
      data.push(
        {
          type: 'bar' as const,
          label: 'Tomorrow',
          data: prices?.tomorrowPrices.map(c =>  c.total) as number[],
          borderColor: 'rgb(178,118,178)',
          backgroundColor: 'rgba(0,180,255,1)',
        });
    }

    setBarState(data);
  }, [prices])

  return <CChart
    type="bar"
    data={{
      labels: prices?.todayPrices.map(p => dayjs(p.startsAt).format('HH:mm')),
      datasets: barState
    }}
  />
}
import { ConsumptionAverageChart } from "../components/ConsumptionAverageChart/ConsumptionAverageChart"
import { ConsumptionBarChart } from "../components/ConsumptionBarChart/ConsumptionBarChart"
import { ConsumptionStats } from "../components/ConsumptionStats/ConsumptionStats"
import { PriceInfoChart } from "../components/PriceInfoChart/PriceInfoChart"
import { PriceInfoStats } from "../components/PriceInfoStats/PriceInfoStats"
import { QueryParameterInput } from "../components/QueryParamaterInput/QueryParamaterInput"
import { Card } from "../components/Shared/Card/Card"

export const Dashboard = () => {
  return (
    <div>
      <Card>
        <QueryParameterInput />
      </Card>
      <Card>
        <ConsumptionBarChart />
      </Card>
      <Card>
        <ConsumptionAverageChart />
      </Card>
      <Card noBody>
        <ConsumptionStats />
      </Card>
      <Card header="Energy prices">
        <PriceInfoChart />
        <PriceInfoStats />
      </Card>
    </div>
  )
}
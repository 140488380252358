import { CFormInput } from "@coreui/react"
import { CFormInputProps } from "@coreui/react/dist/components/form/CFormInput";
import { useEffect, useState } from "react";

export const DateInput: React.FC<CFormInputProps & { onDateChanged: (date: Date|null) => void }> = (props) => {
  const [localDate, setLocalDate] = useState(props.value);

  const { onDateChanged, ...other } = props;

  useEffect(() => {
    setLocalDate(props.value);
  }, [props.value])

  return (
    <CFormInput 
      {...other}
      onChange={(event) => {
        setLocalDate(event.target.value);
        onDateChanged(event.target.valueAsDate);
      }}
      type="date"
      value={localDate}
    />
  );
}
import { selector } from "recoil";
import { IConsumption } from "tibber-api/lib/src/models/IConsumption";
import { consumption } from "../../queries/consumption.gql";
import { BuildConsumptionQuery } from "../../utils/queryBuilder";
import { getFromToDate } from "../../utils/time";
import { ConsumptionWithTariff } from "../configuration/models";
import { configurationState, queryParameterState, tibberClientState } from "../configuration/state";
import { calculateTariff, supplierState, supplierTariffState } from "../supplier/state";

export const consumptionState = selector<Array<IConsumption>>({
  key: 'consumptionState',
  get: async ({get}) => {
    const tibberClient = get(tibberClientState);
    const configuration = get(configurationState);
    const query = get(queryParameterState);

    if (tibberClient === undefined || configuration.homeId === undefined)
      return [];

    if (query.dateRangePreset === undefined || (query.dateFrom === null || query.dateTo === null))
      return [];

    const dateRange = getFromToDate(query.dateRangePreset, query.dateFrom, query.dateTo);

    const queryParams = BuildConsumptionQuery(configuration.homeId, dateRange.dateFrom, dateRange.dateTo, query.resolution);
  
    const result = await tibberClient.query(consumption, queryParams);
    if (result && result.viewer && result.viewer.home) {
      const home = result.viewer.home;
      return Object.assign([], home.consumption ? home.consumption.nodes : []);
    }
    
    return [];
  }
});

export const consumptionWithTariffState = selector<Array<ConsumptionWithTariff>>({
  key: "consumptionWithTariffState",
  get: ({get}) => {
    const consumption = get(consumptionState);
    const supplier = get(supplierState);
    const supplierTariff = get(supplierTariffState);

    if (!supplier || !supplierTariff)
      return [];
    
    return consumption.map(c => ({
      ...c,
      ...calculateTariff(supplierTariff, c, supplier.treatWeekendAsNight)
    }));
  }
});

import { CChart } from '@coreui/react-chartjs';
import { useRecoilValue } from 'recoil';
import dayjs, { Dayjs } from 'dayjs';
import lodash from 'lodash';
import { consumptionState } from '../../features/consumption/state';
import { queryParameterState } from '../../features/configuration/state';
import { EnergyResolution } from 'tibber-api/lib/src/models/enums/EnergyResolution';
import { MappedConsumption } from '../../features/consumption/models';

const options: any = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    }
  },
  scales: {
    x: {
      ticks: {
        display: false
      }
    }
  }
};

export const ConsumptionAverageChart = () => {
  const consumption = useRecoilValue(consumptionState);
  const queryParams = useRecoilValue(queryParameterState);

  const getGroupBy = (dateFrom: Dayjs) => {
    switch (queryParams.resolution) {
      case EnergyResolution.HOURLY:
        return `${dateFrom.format('DD.MM.YYYY')}`;
      case EnergyResolution.DAILY:
        return `Week ${dateFrom.week()} ${dateFrom.year()}`;
      case EnergyResolution.WEEKLY:
        return dateFrom.format('MM YYYY');
      case EnergyResolution.MONTHLY:
        return dateFrom.format('YYYY');
      case EnergyResolution.ANNUAL:
        return dateFrom.format('DD.MM.YYYY');
    }
  }

  const datasetLabel = () => {
    switch (queryParams.resolution) {
      case EnergyResolution.HOURLY:
        return `Daily`;
      case EnergyResolution.DAILY:
        return `Weekly`;
      case EnergyResolution.WEEKLY:
        return `Monthly`;
      case EnergyResolution.MONTHLY:
        return `Yearly`;
      case EnergyResolution.ANNUAL:
        return '';
    }
  }

  const mapped = consumption.map(c => {
    return {
      ...c,
      mapped: getGroupBy(dayjs(c.from))
    } as MappedConsumption
  })

  const group = lodash.groupBy(mapped, c => c.mapped);
  const labels = Object.keys(group);
  
  return <CChart
  options={options}
  type="line" 
  data={{
    labels: labels,
    datasets: [
      {
        type: 'line' as const,
        label: `${datasetLabel()} avg cost`,
        data: labels.map(l => lodash.meanBy(group[l], c => c.cost)),
        borderWidth: 0.5,
        borderColor: 'rgb(250,164,58)',
        backgroundColor: 'rgba(250,164,58,0.6)',
      },
      {
        type: 'line' as const,
        label: `${datasetLabel()} avg consumption`,
        data: labels.map(l => lodash.meanBy(group[l], c => c.consumption)),
        borderColor: 'rgb(96,189,104)',
        borderWidth: 0.5,
        backgroundColor: 'rgba(96,189,104,0.6)',
      },
      {
        type: 'line' as const,
        label: `${datasetLabel()} avg price`,
        data: labels.map(l => lodash.meanBy(group[l], c => c.unitPrice)),
        borderWidth: 0.5,
        borderColor: 'rgb(93,165,218)',
        backgroundColor: 'rgba(93,165,218,0.6)',
      }
    ]}
  }
/>
}

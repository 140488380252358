import { atom, selectorFamily } from "recoil";
import { localStorageEffect } from "../../utils/stateEffect";
import { Dataset } from "./models";

export const datasetQueries = atom<Array<Dataset>>({
  key: 'datasetQueries',
  default: [],
  effects: [localStorageEffect('datasetQueries')]
});

export const datasetById = selectorFamily<Dataset, string>({
  key: 'datasetById',
  get: (id: string) => ({get}) => {
    const dataset = get(datasetQueries).find(d => d.id === id);
    if (dataset === undefined)
      throw new Error("Dataset not found");
    
    return dataset;
  }
})

export const activeDataset = atom<string>({
  key: 'activeDataset',
  default: ''
})

import dayjs from "dayjs";
import { selector } from "recoil"
import { IConsumption } from "tibber-api/lib/src/models/IConsumption";
import { Supplier, SupplierTariffStage, Weekday } from "../configuration/models"
import { configurationState } from "../configuration/state";
import { statsState } from "../stats/state";
import { supplier as norgesnett } from "./norgesnett.supplier"
import { supplier as elvia } from "./elvia.supplier";

export const suppliers: Supplier[] = [
    norgesnett,
    elvia
]

export const supplierState = selector<Supplier|undefined>({
    key: "supplierState",
    get: ({get}) => {
        const configuration = get(configurationState);

        if (!configuration.supplier)
            return undefined;

        return suppliers.find(s => s.name === configuration.supplier);
    }
});

export const supplierTariffState = selector<SupplierTariffStage|undefined>({
    key: "supplierTariff",
    get: ({get}) => {
        const supplier = get(supplierState);
        if (!supplier)
            return undefined;

        const stats = get(statsState);

        return getTariff(supplier, stats?.averagePeakPrice as number);
    }
});

export const getTariff = (supplier: Supplier, meanConsumption: number) => {
    return supplier.tariffs.find(s => meanConsumption >= s.rangeFrom && meanConsumption <= s.rangeTo);
}

export const calculateTariff = (tariff: SupplierTariffStage, consumption: IConsumption, treatWeekendAsNight: boolean = false) => {
    const date = dayjs(consumption.from);
    const day = date.day();

    let hourIsDay = isDay(date.hour())

    if (treatWeekendAsNight && (day === Weekday.SATURDAY || day === Weekday.SUNDAY))
        hourIsDay = true;

    return {isDay: hourIsDay, tariff: hourIsDay ? tariff.priceDay : tariff.priceNight}
}

const isDay = (hour: number) => {
    return hour >= 6 && hour + 1 <= 22;
}

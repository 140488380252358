import dayjs from "dayjs";
import { FC } from "react";
import { CSVLink } from "react-csv";
import { useRecoilValue } from "recoil"
import { consumptionState } from "../../features/consumption/state";

export const ConsumptionCsvDownload: FC = () => {
  const consumption = useRecoilValue(consumptionState);

  if (consumption === undefined)
    return null;

  const mapped = consumption?.map(c => {
    return {
      from: dayjs(c.from).format("DD.MM.YYYY HH:mm"),
      to: dayjs(c.to).format("DD.MM.YYYY HH:mm"),
      unitPrice: c.unitPrice.toLocaleString(),
      unitPriceVAT: c.unitPriceVAT.toLocaleString(),
      consumption: c.consumption ? c.consumption.toLocaleString() : "",
      consumptionUnit: c.consumptionUnit,
      cost: c.cost ? c.cost.toLocaleString() : "",
      currency: c.currency 
    }
  });

  return (<CSVLink filename="TibberExport.csv" data={mapped}>Download</CSVLink>)
}
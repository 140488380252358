import { selector } from "recoil";
import _ from "lodash";
import { consumptionState } from "../consumption/state";
import { Stats } from "../configuration/models";

export const statsState = selector<Stats|null>({
    key: "statsState",
    get: ({get}) => {
        const consumption = get(consumptionState);
        if (!consumption)
            return null;

        const totalCost = _.sumBy(consumption, c => c.cost);
        const totalConsumption = _.sumBy(consumption, c => c.consumption);
      
        const topConsumption = _.orderBy(consumption.filter(c => c.consumption !== null), c => c.consumption, "desc")[0] ?? {};
        const lowConsumption = _.orderBy(consumption, c => c.consumption, "asc")[0] ?? {};
        const topPrice = _.orderBy(consumption, c => c.unitPrice, "desc")[0] ?? {};
        const lowPrice = _.orderBy(consumption, c => c.unitPrice, "asc")[0] ?? {};
        const topCost = _.orderBy(consumption.filter(c => c.cost !== null), c => c.cost, "desc")[0] ?? {};
        const lowCost = _.orderBy(consumption, c => c.cost, "asc")[0] ?? {};
      
        const averagePrice = _.meanBy(consumption, c => c.unitPrice);
        const averageConsumption = _.meanBy(consumption, c => c.consumption);
        const averageCost = _.meanBy(consumption, c => c.cost);
      
        const consumptionPeaks = _.orderBy(consumption.filter(c => c.consumption !== null), c => c.consumption, "desc").slice(0, 3);
        const averagePeakPrice = _.meanBy(consumptionPeaks, c => c.consumption);

        return {
            totalCost: totalCost,
            totalConsumption: totalConsumption,
            highestConsumption: topConsumption,
            lowestConsumption: lowConsumption,
            highestPrice: topPrice,
            lowestPrice: lowPrice,
            highestCost: topCost,
            lowestCost: lowCost,
            averagePrice: averagePrice,
            averageConsumption: averageConsumption,
            averageCost: averageCost,
            averagePeaks: consumptionPeaks,
            averagePeakPrice: averagePeakPrice
        }
    }
});
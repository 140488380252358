import { EnergyResolution } from "tibber-api/lib/src/models/enums/EnergyResolution";
import { IConsumption } from "tibber-api/lib/src/models/IConsumption";

export interface Configuration {
  accessToken?: string,
  homeId?: string,
  supplier?: string
}

export interface QueryParameters {
  resolution: EnergyResolution,
  count: number,
  dateFrom: Date|null,
  dateTo: Date|null,
  dateRangePreset?: DateRangePreset
}

export enum DateRangePreset {
  NOT_SET = "",
  TODAY = "Today",
  YESTERDAY = "Yesterday",
  THIS_WEEK = "This week",
  LAST_WEEK = "Last week",
  THIS_MONTH = "This month",
  LAST_MONTH = "Last month",
  CUSTOM = "Custom"
}

export enum Months {
  JANUARY = 1,
  FEBRUARY = 2,
  MARS = 3,
  APRIL = 4,
  MAY = 5,
  JUNE = 6,
  JULY = 7,
  AUGUST = 8,
  SEPTEMBER = 9,
  OCTOBER = 10,
  NOVEMBER = 11,
  DECEMBER = 12
}

export enum Weekday {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6
}

export interface Supplier {
  name: string
  treatWeekendAsNight: boolean
  tariffs: SupplierTariffStage[]
}

export interface SupplierTariffStage {
  stage: number
  priceDay: number
  priceNight: number
  priceMonth: number
  rangeFrom: number
  rangeTo: number
}

export interface Stats {
  totalCost: number
  totalConsumption: number
  highestConsumption: IConsumption
  lowestConsumption: IConsumption
  highestPrice: IConsumption
  lowestPrice: IConsumption
  highestCost: IConsumption
  lowestCost: IConsumption
  averagePrice: number
  averageConsumption: number
  averageCost: number
  averagePeaks: IConsumption[]
  averagePeakPrice: number
}

export interface ConsumptionWithTariff extends IConsumption {
  tariff: number
  isDay: boolean
}

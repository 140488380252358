const randomInteger = (max: number) => {
  return Math.floor(Math.random()*(max + 1));
}

export const useColorUtil = () => {
  const randomRGB = () => {
    return [randomInteger(255), randomInteger(255), randomInteger(255)];
  }

  const randomHex = () => {
    const [r,g,b] = randomRGB();
    const hr = r.toString(16).padStart(2, '0');
    const hg = g.toString(16).padStart(2, '0');
    const hb = b.toString(16).padStart(2, '0');

    return `#${hr}${hg}${hb}`;
  }

  return {randomRGB, randomHex};
}

import { useRecoilValue } from "recoil";
import dayjs from "dayjs";
import { CRow, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from "@coreui/react";
import { toLocaleNumberString } from "../../utils/formatters";
import { ConsumptionCsvDownload } from "../ConsumptionCsvDownload/ConsumptionCsvDownload";
import { consumptionState } from "../../features/consumption/state";

export const ConsumptionTable = () => {
  const consumption = useRecoilValue(consumptionState);

  return (
    <CRow>
      <ConsumptionCsvDownload />
      <CTable hover>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell>From</CTableHeaderCell>
            <CTableHeaderCell>To</CTableHeaderCell>
            <CTableHeaderCell>Cost</CTableHeaderCell>
            <CTableHeaderCell>Unit Price</CTableHeaderCell>
            <CTableHeaderCell>Consumption</CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {consumption !== undefined && consumption.map((c, i) => {
            return (
              <CTableRow key={i}>
                <CTableDataCell>{dayjs(c.from).format('DD.MM.YYYY HH:mm')}</CTableDataCell>
                <CTableDataCell>{dayjs(c.to).format('DD.MM.YYYY HH:mm')}</CTableDataCell>
                <CTableDataCell className="right">{toLocaleNumberString(c.cost)}</CTableDataCell>
                <CTableDataCell className="right">{toLocaleNumberString(c.unitPrice)}</CTableDataCell>
                <CTableDataCell className="right">{toLocaleNumberString(c.consumption)}</CTableDataCell>
              </CTableRow>
          )})}
        </CTableBody>
      </CTable>
    </CRow>
  );
}

import { CButton, CCol, CFormInput, CRow } from "@coreui/react"
import dayjs from "dayjs"
import { useEffect, useState } from "react";
import { DateRangePreset } from "../../features/configuration/models";
import { DatasetQuery } from "../../features/datasets/models"
import { useColorUtil } from "../../hooks/useColorUtil";
import { getFromToDate } from "../../utils/time";
import { ChartPropSelect } from "../FormInput/ChartPropSelect";
import { DateInput } from "../FormInput/DateInput"
import { DateRangePresetSelect } from "../FormInput/DateRangePresetSelect";

interface DatasetQueryInputProps {
  datasetQuery: DatasetQuery,
  onItemChanged: (datasetQuery: DatasetQuery) => void;
  onDeleteClick: (datasetQuery: DatasetQuery) => void;
}

export const DatasetQueryInput: React.FC<DatasetQueryInputProps> = (props) => {
  const [localState, setLocalState] = useState(props.datasetQuery);
  const { randomRGB } = useColorUtil();

  const dateRangeChanged = (dateRange: DateRangePreset) => {
    const range = getFromToDate(dateRange, localState.dateFrom as Date, localState.dateTo as Date);

    setLocalState({...localState, dateRangePreset: dateRange, dateFrom: range.dateFrom, dateTo: range.dateTo});
  }

  useEffect(() => {
    props.onItemChanged(localState);
  }, [localState])

  const fieldChanged = (field: keyof DatasetQuery, value: any) => {
    const state = {...localState};
    state[field] = value;
    setLocalState({...state});
  }

  return (
    <CRow className="align-middle">
      <CCol sm="11" className="me-auto">
        <CRow>
          <CCol>
            <CFormInput
              id="name"
              type="text"
              value={localState.name ?? ''}
              onChange={(event) => fieldChanged("name", event.target.value)}
            />
          </CCol>
          <CCol>
            <DateRangePresetSelect
              onChange={(event) => dateRangeChanged(event.target.value as DateRangePreset)}
              defaultValue={localState.dateRangePreset}
            />
          </CCol>
          <CCol>
            <DateInput
              id="dateFrom"
              disabled={localState.dateRangePreset !== undefined && localState.dateRangePreset !== DateRangePreset.CUSTOM}
              value={localState.dateFrom ? dayjs(localState.dateFrom).format('YYYY-MM-DD') : ''}
              onDateChanged={date => fieldChanged("dateFrom", date)}
            />
          </CCol>
          <CCol>
            <DateInput
              id="dateTo"
              disabled={localState.dateRangePreset !== undefined && localState.dateRangePreset !== DateRangePreset.CUSTOM}
              value={localState.dateTo ? dayjs(localState.dateTo).format('YYYY-MM-DD') : ''}
              onDateChanged={date => fieldChanged("dateTo", date)}
            />
          </CCol>
          <CCol>
            <ChartPropSelect id="chartProp" value={localState.chartProp} onChange={(event) => setLocalState({...localState, chartProp: event.target.value})} />
          </CCol>
          <CCol>
          <div onClick={() => fieldChanged("color", randomRGB())} style={{height: '100%', backgroundColor: `rgb(${localState.color[0]},${localState.color[1]},${localState.color[2]})`}} />
        </CCol>
        </CRow>
      </CCol>
      <CCol>
        <CButton size="sm" color="danger" onClick={() => props.onDeleteClick(localState)}>&times;</CButton>
      </CCol>
    </CRow>
  );
}

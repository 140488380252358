import { CAlert, CCol, CFormSelect, CListGroup, CRow } from "@coreui/react";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil"
import { queryParameterState } from "../../features/configuration/state";
import { consumptionWithTariffState } from "../../features/consumption/state"
import { DateRangePreset, Months } from "../../features/configuration/models";
import { EnergyResolution } from "tibber-api/lib/src/models/enums/EnergyResolution";
import _ from "lodash";
import { CChart } from "@coreui/react-chartjs";
import { supplierTariffState } from "../../features/supplier/state";
import { Card } from "../Shared/Card/Card";
import { StatItem } from "../Shared/StatItem/StatItem";
import { toLocaleNumberString } from "../../utils/formatters";
import { ConsumtionSupport } from "../../features/consumption/models";

function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
    return Object.keys(obj).filter(k => Number.isNaN(+k)) as K[];
}

const getYears = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = 2022; i <= currentYear; i++) {
        years.push(i);
    }

    return years;
}

interface TotalStats {
    totalCost: number
    totalToPay: number;
    effectiveAvgPrice: number
}

export const ConsumptionAnalytics = () => {
    const consumption = useRecoilValue(consumptionWithTariffState);
    const supplierTariff = useRecoilValue(supplierTariffState);
    const [query, setQuery] = useRecoilState(queryParameterState);

    const [local, setLocal] = useState<any>({
        month: dayjs().month() + 1,
        year: dayjs().year()
    });
    const [support, setSupport] = useState<ConsumtionSupport>();
    const [totalStats, setTotalStats] = useState<TotalStats>();

    const updateQuery = (month: number, year: number) => {
        const thisMonth = dayjs(`${year}-${month}-01`);
        const tempQuery = {...query};
        tempQuery.dateRangePreset = DateRangePreset.CUSTOM;
        tempQuery.resolution = EnergyResolution.HOURLY;
        tempQuery.dateFrom = thisMonth.startOf("day").startOf("month").toDate();
        tempQuery.dateTo = thisMonth.endOf("day").endOf("month").toDate();

        setQuery(tempQuery);
    }

    useEffect(() => {
        updateQuery(local.month, local.year);
    }, [local])

    const factor = (query.dateFrom as Date) >= new Date('2022-08-31') ? 0.9 : 0.8;

    useEffect(() => {
        const mappedSupportPrice = consumption.map(c => (((c.unitPrice * 100 - 1) - 87.5)) * factor);
        let meanPrice = _.mean(mappedSupportPrice);
        if (meanPrice < 0)
            meanPrice = 0;
        
        const totalCost = _.sumBy(consumption, c => c.cost * 100);
        const totalConsumption = _.sumBy(consumption, c => c.consumption);
        const totalSupport = totalConsumption * meanPrice;
        
        const localSupport: ConsumtionSupport = {
            meanPrice: meanPrice,
            total: (totalCost - totalSupport) / 100,
            support: totalSupport / 100
        };

        const totalFullCost = _.sum([
            localSupport?.support,
            localSupport?.total,
            _.sumBy(consumption, c => (c.consumption * c.tariff) / 100),
            supplierTariff?.priceMonth
        ]);

        const effectiveAvgPrice = (totalFullCost - localSupport.support) / totalConsumption;

        setTotalStats({
            totalCost: totalFullCost,
            totalToPay: totalFullCost - localSupport.support,
            effectiveAvgPrice: effectiveAvgPrice
        });
        setSupport(localSupport);
    }, [consumption]);

    return (
        <>
            <Card>
                <CAlert color="warning">
                    This feature is currently in beta! 😎
                </CAlert>
                <CRow>
                    <CCol>
                        <CFormSelect value={local.month} onChange={(event) => setLocal({...local, month: event.target.value})}>
                            {enumKeys(Months).map((e, i) => (<option key={i} value={Months[e]}>{e}</option>))}
                        </CFormSelect>
                    </CCol>
                    <CCol>
                        <CFormSelect value={local.year} onChange={(event) => setLocal({...local, year: event.target.value})}>
                            {getYears().map(y => (<option key={y} value={y}>{y}</option>))}
                        </CFormSelect>
                    </CCol>
                </CRow>
                {dayjs().month() + 1 === local.month && dayjs().year() === local.year &&
                    <CAlert className="mt-3 mb-0" color="info">
                        This month is not ended, and the prices you see here is just a "guesstimate" 😘 🤑
                    </CAlert>
                }
                {query.dateFrom! < new Date('2022-07-01') &&
                    <CAlert className="mt-3 mb-0" color="danger">
                        This feature is only available on data newer than 01.07.2022! The analysis and totals is not correct! 🤑
                    </CAlert>
                }
            </Card>
            <CRow>
                <CCol>
                    <Card>
                        {support &&
                            <CChart
                            type="doughnut"
                            data={{
                                labels: ['Strømstøtte', 'Strøm', 'Nettleie dag', 'Nettleie natt', 'Kapasitetsledd'],
                                datasets: [
                                {
                                    backgroundColor: ['#41B883', '#E46651', 'red', 'blue', 'purple'],
                                    hoverOffset: 10,
                                    data: [
                                        support.support,
                                        support.total,
                                        _.sumBy(consumption.filter(c => c.isDay), c => (c.consumption * c.tariff) / 100),
                                        _.sumBy(consumption.filter(c => !c.isDay), c => (c.consumption * c.tariff) / 100),
                                        supplierTariff?.priceMonth ?? 0
                                    ],
                                }]
                            }}
                            />
                        }
                    </Card>
                </CCol>
                <CCol>
                    <Card noBody>
                        <CListGroup>
                            <StatItem title="Total" value={toLocaleNumberString(totalStats?.totalCost)} />
                            <StatItem title="Support" value={toLocaleNumberString(support?.support)} />
                            <StatItem title="Your total" value={toLocaleNumberString(totalStats?.totalToPay)} />
                            <StatItem title="Your effective avg price" value={toLocaleNumberString(totalStats?.effectiveAvgPrice)} />
                        </CListGroup>
                    </Card>
                </CCol>
            </CRow>
        </>
    )
}

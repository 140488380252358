import { CFormSelect } from "@coreui/react";
import { CFormSelectProps } from "@coreui/react/dist/components/form/CFormSelect";
import { DateRangePreset } from "../../features/configuration/models";

export const DateRangePresetSelect: React.FC<CFormSelectProps> = (props) => {
  return (
    <CFormSelect {...props}>
      {Object.keys(DateRangePreset).filter(k => isNaN(k as any)).map(((k, i) => {
        const value = DateRangePreset[k as keyof typeof DateRangePreset];
        return (
          <option key={`${k}${i}`} value={value}>{value}</option>
        )
      }))}
    </CFormSelect>
  );
}
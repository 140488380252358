import { CChart } from '@coreui/react-chartjs';
import { useRecoilValue } from 'recoil';
import dayjs from 'dayjs';
import { consumptionState } from '../../features/consumption/state';

const options: any = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    }
  },
  scales: {
    x: {
      ticks: {
        display: false
      }
    }
  }
};

export const ConsumptionBarChart = () => {
  const consumption = useRecoilValue(consumptionState);

  const labels = consumption?.map(c => dayjs(c.from).format('DD.MM.YYYY HH:mm'));

  return <CChart
  options={options}
  type="line" 
  data={{
    labels: labels,
    datasets: [
      {
        type: 'line' as const,
        label: 'Cost',
        data: consumption?.map(c =>  c.cost) as number[],
        borderWidth: 0.5,
        borderColor: 'rgb(250,164,58)',
        backgroundColor: 'rgba(250,164,58,0.6)',
      },
      {
        type: 'line' as const,
        label: 'Consumption',
        data: consumption?.map(c =>  c.consumption) as number[],
        borderColor: 'rgb(96,189,104)',
        borderWidth: 0.5,
        backgroundColor: 'rgba(96,189,104,0.6)',
      },
      {
        type: 'line' as const,
        label: 'Price',
        data: consumption?.map(c =>  c.unitPrice) as number[],
        borderWidth: 0.5,
        borderColor: 'rgb(93,165,218)',
        backgroundColor: 'rgba(93,165,218,0.6)',
      }
    ],
  }}
/>
}

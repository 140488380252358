import { CButton, CCol, CForm, CRow } from "@coreui/react";
import dayjs from "dayjs";
import { useState } from "react";
import { useRecoilState } from "recoil"
import { EnergyResolution } from "tibber-api/lib/src/models/enums/EnergyResolution";
import { DateRangePreset } from "../../features/configuration/models";
import { queryParameterState } from "../../features/configuration/state";
import { getFromToDate } from "../../utils/time";
import { DateInput } from "../FormInput/DateInput";
import { DateRangePresetSelect } from "../FormInput/DateRangePresetSelect";
import { ResolutionSelect } from "../FormInput/ResolutionSelect";

export const QueryParameterInput = () => {
  const [queryParams, setQueryParams] = useRecoilState(queryParameterState);
  const [localQuery, setLocalQuery] = useState(queryParams);

  const dateFrom = localQuery.dateFrom ? dayjs(localQuery.dateFrom).format('YYYY-MM-DD') : '';
  const dateTo = localQuery.dateTo ? dayjs(localQuery.dateTo).format('YYYY-MM-DD') : '';

  const dateRangeChanged = (dateRange: DateRangePreset) => {
    const range = getFromToDate(dateRange, localQuery.dateFrom as Date, localQuery.dateTo as Date);

    setLocalQuery({...localQuery, dateRangePreset: dateRange, dateFrom: range.dateFrom, dateTo: range.dateTo});
  }

  return (
    <CForm onSubmit={(event) => {
      event.preventDefault();
      setQueryParams(localQuery);
    }}>
      <CRow>
        <CCol>
          <DateRangePresetSelect 
            onChange={event => dateRangeChanged(event.target.value as DateRangePreset)} 
            defaultValue={localQuery.dateRangePreset}
          />
        </CCol>
        <CCol>
          <DateInput 
            id="dateFrom"
            disabled={localQuery.dateRangePreset !== undefined && localQuery.dateRangePreset !== DateRangePreset.CUSTOM}
            onDateChanged={(date) => {
              setLocalQuery({...localQuery, dateFrom: date});
            }}
            value={dateFrom}
            max={localQuery.dateTo !== null ? dateTo : dayjs().format('YYYY-MM-DD')}
          />
        </CCol>
        <CCol>
          <DateInput 
            id="dateTo"
            disabled={localQuery.dateRangePreset !== undefined && localQuery.dateRangePreset !== DateRangePreset.CUSTOM}
            onDateChanged={(date) => {
              setLocalQuery({...localQuery, dateTo: date});
            }}
            value={dateTo}
            min={dateFrom}
            max={dayjs().format('YYYY-MM-DD')}
          />
        </CCol>
        <CCol>
          <ResolutionSelect 
            id="resolution" 
            defaultValue={localQuery.resolution} 
            onChange={(event) => setLocalQuery({...localQuery, resolution: event.target.value as EnergyResolution})} 
          />
        </CCol>
        <CCol sm="auto">
          <CButton variant="outline" color="primary" type="submit">Apply</CButton>
        </CCol>
      </CRow>
    </CForm>
  )
}

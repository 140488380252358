import { CFormSelect } from "@coreui/react";
import { CFormSelectProps } from "@coreui/react/dist/components/form/CFormSelect";
import { EnergyResolution } from "tibber-api/lib/src/models/enums/EnergyResolution";

export const ResolutionSelect: React.FC<CFormSelectProps> = (props) => {
  return (
    <CFormSelect {...props}>
      <option value={EnergyResolution.HOURLY}>Hourly</option>
      <option value={EnergyResolution.DAILY}>Daily</option>
      <option value={EnergyResolution.WEEKLY}>Weekly</option>
      <option value={EnergyResolution.MONTHLY}>Monthly</option>
      <option value={EnergyResolution.ANNUAL}>Annual</option>
    </CFormSelect>
  );
}
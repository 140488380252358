import { EnergyResolution } from "tibber-api/lib/src/models/enums/EnergyResolution"

export const consumption = `
query getConsumption($homeId:ID! $resolution: EnergyResolution! $count:Int! $after:String){
  viewer {
    home(id:$homeId) {
      id
      consumption(resolution: $resolution, first: $count, after: $after) {
        nodes {
          from
          to
          cost
          unitPrice
          unitPriceVAT
          consumption
          consumptionUnit
          currency
        }
      }
    }
  }
}
`
export interface ConsumptionQueryParams {
  homeId: string,
  resolution: EnergyResolution,
  count: number,
  after: string
}

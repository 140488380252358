import { selector } from "recoil";
import { configurationState, tibberClientState } from "../configuration/state";

export const priceInfoState = selector({
  key: 'priceInfoState',
  get: async ({get}) => {
    const tibberClient = get(tibberClientState);
    const configuration = get(configurationState);

    if (tibberClient === undefined || configuration.homeId === undefined)
      return;

    const todayPrices = await tibberClient.getTodaysEnergyPrices(configuration.homeId);
    const tomorrowPrices = await tibberClient.getTomorrowsEnergyPrices(configuration.homeId);

    return {
      todayPrices,
      tomorrowPrices
    }
  }
});

import dayjs from "dayjs";
import { EnergyResolution } from "tibber-api/lib/src/models/enums/EnergyResolution";
import { ConsumptionQueryParams } from "../queries/consumption.gql";
import { convertToNumberOfItems } from "./time";

export const BuildConsumptionQuery = (homeId: string, dateFrom: Date, dateTo: Date, resolution: EnergyResolution): ConsumptionQueryParams => {
  const numberOfItems = convertToNumberOfItems(resolution, dayjs(dateTo).endOf('day').add(1, 'hour').diff(dayjs(dateFrom)));
  const afterHash = btoa(dayjs(dateFrom).startOf('day').add(-1, 'hour').format('YYYY-MM-DDTHH:mm:ss.SSSZ'));

  return {
    homeId: homeId,
    after: afterHash,
    count: numberOfItems,
    resolution: resolution
  }
}
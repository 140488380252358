import { CButton, CCard, CCardHeader, CCol, CForm, CFormInput, CFormLabel, CFormSelect, CListGroup, CListGroupItem, CRow } from "@coreui/react";
import { useState } from "react";
import { EnergyResolution } from "tibber-api/lib/src/models/enums/EnergyResolution";
import { Dataset, DatasetQuery } from "../../features/datasets/models"
import { useColorUtil } from "../../hooks/useColorUtil";
import { ResolutionSelect } from "../FormInput/ResolutionSelect";
import { DatasetQueryInput } from "./DatasetQueryInput";

interface DatasetFormProps {
  dataset: Dataset,
  onChange: (dataset: Dataset) => void
}

export const DatasetForm: React.FC<DatasetFormProps> = (props) => {
  const [localState, setLocalState] = useState(props.dataset);
  const { randomRGB } = useColorUtil();

  return (
    <CForm
      onSubmit={(event) => {
        event.preventDefault();
        props.onChange({...localState});
      }}
    >
      <CRow>
        <CCol>
          <CFormLabel htmlFor="name">Dataset name</CFormLabel>
          <CFormInput
            className="mb-3"
            id="name"
            value={localState.name} 
            placeholder="Name"
            onChange={(event) => { setLocalState({...localState, name: event.target.value})}} 
          />
        </CCol>
        <CCol>
          <CFormLabel htmlFor="resolution">Resolution</CFormLabel>
          <ResolutionSelect
            className="mb-3"
            id="resolution" 
            defaultValue={localState.resolution} 
            onChange={(event) => setLocalState({...localState, resolution: event.target.value as EnergyResolution})} 
          />
        </CCol>
        <CCol>
          <CFormLabel htmlFor="chartType">Chart type</CFormLabel>
          <CFormSelect id="chartType" value={localState.chartType} onChange={(event) => setLocalState({...localState, chartType: event.target.value})}>
            <option value="line">Line</option>
            <option value="bar">Bar</option>
          </CFormSelect>
        </CCol>
      </CRow>
      <CCard className="mb-3">
        <CCardHeader>
          <CRow>
            <CCol className="col-auto me-auto">Queries</CCol>
            <CCol className="col-auto">
              <CButton className="pull-right" color="info" size="sm" onClick={() => {
                const tempQueries = [...localState.queries];
                tempQueries.push({id: new Date().toISOString(), color: randomRGB()} as DatasetQuery);
                setLocalState({...localState, queries: tempQueries });
              }}>
                Add query
              </CButton>
            </CCol>
          </CRow>
        </CCardHeader>
        <CListGroup flush>
          {localState.queries.map((query, i) => {
            return (
              <CListGroupItem key={query.id}>
                <DatasetQueryInput 
                  datasetQuery={query} 
                  onItemChanged={(q) => {
                    const tempQueries = [...localState.queries];
                    tempQueries[i] = q;

                    setLocalState({...localState, queries: tempQueries});
                  }}
                  onDeleteClick={() => {
                    const tempQueries = [...localState.queries];

                    tempQueries.splice(i, 1);
                    setLocalState({...localState, queries: tempQueries});
                  }}
                />
              </CListGroupItem>
            )
          })}
        </CListGroup>
      </CCard>
      <CRow>
        <CCol className="align-self-end">
          <CButton
            type="submit"
          >
            Save
          </CButton>
        </CCol>
      </CRow>
    </CForm>
  );
}
import { CButton, CCollapse, CContainer, CNavbar, CNavbarBrand, CNavbarNav, CNavbarToggler, CNavItem } from "@coreui/react"
import { useState } from "react";
import { Link } from "react-router-dom";
import { useSetRecoilState } from "recoil"
import { openSettingsState } from "../../../features/configuration/state";

export const Header = () => {
  const setOpenSettings = useSetRecoilState(openSettingsState);
  const [navVisible, setNavVisible] = useState(false);

  return (
    <CNavbar expand="lg" colorScheme="light" className="bg-white">
      <CContainer>
        <CNavbarBrand>Tibbalizer</CNavbarBrand>
        <CNavbarToggler onClick={() => setNavVisible(!navVisible)} />
        <CCollapse className="navbar-collapse d-flex justify-content-start" visible={navVisible}>
          <CNavbarNav>
            <CNavItem>
              <Link className="nav-link" to="/">Home</Link>
            </CNavItem>
            <CNavItem>
              <Link className="nav-link" to="/data">Data</Link>
            </CNavItem>
            <CNavItem>
              <Link className="nav-link" to="/datasets">Datasets</Link>
            </CNavItem>
            <CNavItem>
              <Link className="nav-link" to="/analytics">Analytics</Link>
            </CNavItem>
          </CNavbarNav>
        </CCollapse>
        <CButton className="ml-auto p-2" onClick={() => setOpenSettings(true)}>Settings</CButton>
      </CContainer>
    </CNavbar>
    
  )
}

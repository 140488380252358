import { atom, selector } from "recoil";
import { TibberQuery } from "tibber-api";
import { EnergyResolution } from "tibber-api/lib/src/models/enums/EnergyResolution";
import { IApiEndpoint } from "tibber-api/lib/src/models/IConfig";
import { IHome } from "tibber-api/lib/src/models/IHome";
import { localStorageEffect } from "../../utils/stateEffect";
import { Configuration, DateRangePreset, QueryParameters } from "./models";

export const openSettingsState = atom({
  key: 'openSettings',
  default: false
});

export const configurationState = atom<Configuration>({
  key: 'accessToken',
  default: {
    accessToken: undefined,
    homeId: undefined
  },
  effects: [localStorageEffect('accessToken')]
});

export const queryParameterState = atom<QueryParameters>({
  key: 'queryParameters',
  default: {
    resolution: EnergyResolution.HOURLY,
    count: 10,
    dateFrom: null,
    dateTo: null,
    dateRangePreset: DateRangePreset.NOT_SET
  },
  effects: [localStorageEffect('queryParameters')]
});

export const tibberClientConfig = selector<IApiEndpoint|undefined>({
  key: 'tibberClientConfig',
  get: ({get}) => {
    const configuration = get(configurationState);

    if (configuration.accessToken === undefined || configuration.accessToken === '')
      return;

    return {
      apiKey: configuration.accessToken,
      queryUrl: "https://api.tibber.com/v1-beta/gql",
      feedUrl: "wss://api.tibber.com/v1-beta/gql/subscriptions"
    }
  }
});

export const tibberClientState = selector<TibberQuery|undefined>({
  key: 'tibberClient',
  get: ({get}) => {
    const clientConfig = get(tibberClientConfig);

    if (clientConfig === undefined)
      return;

    return new TibberQuery({
      apiEndpoint: clientConfig,
      active: true
    });
  }
});

export const homesState = selector<Array<IHome>>({
  key: 'homeState',
  get: async ({get}) => {
    const tibberClient = get(tibberClientState);

    if (tibberClient === undefined)
      return [];

    try {
      const response = await tibberClient.getHomesComplete();
      return Array.isArray(response) ? response : [];
    } catch (error) {
      return [];
    }
  }
})

import dayjs from "dayjs";
import { EnergyResolution } from "tibber-api/lib/src/models/enums/EnergyResolution";
import { DateRangePreset } from "../features/configuration/models";

export const convertToNumberOfItems = (resolution: EnergyResolution, value: number) => {
  let factor = 3600000;
  
  switch (resolution) {
    case EnergyResolution.HOURLY:
      factor = 3600000;
      break;
    case EnergyResolution.DAILY:
      factor = factor * 24;
      break;
    case EnergyResolution.WEEKLY:
      factor = factor * 24 * 7;
      break;
    case EnergyResolution.MONTHLY:
      factor = factor * 24 * 7 * 4;
      break;
    case EnergyResolution.ANNUAL:
      factor = factor * 24 * 7 * 4 * 12;
      break;
  }

  return Math.floor(value / factor);
}

export const getFromToDate = (preset: DateRangePreset, dateFrom: Date, dateTo: Date) => {
  switch (preset) {
    case DateRangePreset.TODAY:
      return { dateFrom: dayjs().startOf('day').toDate(), dateTo: dayjs().endOf('day').toDate() };
    case DateRangePreset.YESTERDAY:
      return { dateFrom: dayjs().add(-1, 'day').startOf('day').toDate(), dateTo: dayjs().add(-1, 'day').endOf('day').toDate() };
    case DateRangePreset.THIS_WEEK:
      return { dateFrom: dayjs().startOf('week').startOf('day').toDate(), dateTo: dayjs().endOf('week').endOf('day').toDate() };
    case DateRangePreset.LAST_WEEK:
      return { dateFrom: dayjs().add(-1, 'week').startOf('week').startOf('day').toDate(), dateTo: dayjs().add(-1, 'week').endOf('week').endOf('day').toDate() };
    case DateRangePreset.THIS_MONTH:
      return { dateFrom: dayjs().startOf('month').startOf('day').toDate(), dateTo: dayjs().endOf('month').endOf('day').toDate() };
    case DateRangePreset.LAST_MONTH:
      return { dateFrom: dayjs().add(-1, 'month').startOf('month').startOf('day').toDate(), dateTo: dayjs().add(-1, 'month').endOf('month').endOf('day').toDate() };
  }

  return { dateFrom, dateTo };
}

import { CButton, CCard, CCardBody, CCardHeader, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CNav, CNavItem } from "@coreui/react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { EnergyResolution } from "tibber-api/lib/src/models/enums/EnergyResolution";
import { datasetQueries } from "../../features/datasets/state";
import { DatasetForm } from "./DatasetForm";

export const DatasetsInput = () => {
  const [datasets, setDatasets] = useRecoilState(datasetQueries);

  const addDataset = () => {
    const tempLocalState = [...datasets];
    tempLocalState.push({
      id: new Date().toISOString(),
      name: '',
      queries: [],
      resolution: EnergyResolution.DAILY,
      chartType: 'line',
    });

    setDatasets(tempLocalState);
  }

  const removeDataset = (id: string) => {
    const tempLocalState = [...datasets];
    const index = tempLocalState.findIndex(d => d.id === id);

    tempLocalState.splice(index, 1);
    setDatasets(tempLocalState);
  }

  return (
    <div>
      <CButton onClick={() => addDataset()}>Add dataset</CButton>
      {datasets.map((dataset, i) => {
        return (
          <CCard className="mt-3" key={dataset.id}>
            <CCardHeader className="p-0">
              <CNav className="justify-content-end">
                <CNavItem><Link className="nav-link" to={dataset.id}>View this</Link></CNavItem>
              <CDropdown variant="nav-item">
                <CDropdownToggle color="secondary">Actions</CDropdownToggle>
                <CDropdownMenu>
                  <CDropdownItem onClick={() => removeDataset(dataset.id)}>Delete</CDropdownItem>
                </CDropdownMenu>
              </CDropdown>
              </CNav>
            </CCardHeader>
            <CCardBody>
              <DatasetForm
                dataset={dataset} 
                onChange={(updatedSet) => {
                  const tempDatasets = [...datasets];
                  tempDatasets[i] = updatedSet;

                  setDatasets(tempDatasets);
                }} 
              />
            </CCardBody>
          </CCard>
        );
      })}
    </div>
  );
}

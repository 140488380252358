import dayjs from "dayjs";

export const toLocaleNumberString = (input?: number) => {
  if (input === undefined || input === null || Number.isNaN(input))
    return "";
  
  return Number(input.toFixed(2)).toLocaleString('nb-NO', {
    minimumFractionDigits: 2
  });
}

export const toShortDate = (input?: string) => {
  if (input === undefined)
    return "";
    
  return dayjs(input).format('DD.MM.YYYY HH:mm');
}

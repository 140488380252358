import { CCard, CCardBody, CCardHeader } from "@coreui/react";

export const Card: React.FC<{header?: string, noBody?: boolean}> = (props) => {
  return (
      <CCard className="card">
        {props.header &&
          <CCardHeader>
            {props.header}
          </CCardHeader>
        } 
        {!props.noBody &&
          <CCardBody>
            {props.children}
          </CCardBody>
        }
        {props.noBody &&
          props.children
        }
      </CCard>
    );
}

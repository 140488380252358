import React from 'react';
import { useRecoilValue } from 'recoil';
import { AccessTokenInput } from './components/ConfigurationInput/ConfigurationInput';
import { Header } from './components/Shared/Header/Header';
import { CContainer, CSpinner } from '@coreui/react';
import { configurationState } from './features/configuration/state';
import { Route, Routes } from 'react-router-dom';
import { Dashboard } from './pages/Dashboard';
import { Data } from './pages/Data';
import './App.scss';
import { Datasets } from './pages/Datasets';
import { DatasetDetails } from './pages/DatasetDetails';
import { ConsumptionAnalytics } from './components/ConsumptionAnalytics/ConsumptionAnalytics';

function App() {
  const configuration = useRecoilValue(configurationState);
  return (
      <div className="App">
        <Header />
        <React.Suspense fallback={<CSpinner />}>
          <AccessTokenInput />
          <main>
            {configuration && configuration.accessToken && configuration.homeId &&
              <CContainer>
                <Routes>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="data" element={<Data />} />
                  <Route path="datasets" element={<Datasets />} />
                  <Route path="datasets/:id" element={<DatasetDetails />} />
                  <Route path="analytics" element={<ConsumptionAnalytics />} />
                </Routes>
              </CContainer>
            }
          </main>
        </React.Suspense>
      </div>
  );
}

export default App;

import { CListGroup } from "@coreui/react";
import { useRecoilValue } from "recoil";
import { statsState } from "../../features/stats/state";
import { toLocaleNumberString, toShortDate } from "../../utils/formatters";
import { StatItem } from "../Shared/StatItem/StatItem";

export const ConsumptionStats = () => {
  const stats = useRecoilValue(statsState);
  if (!stats)
    return null;

  return (
    <CListGroup>
      <StatItem title="Total cost" value={toLocaleNumberString(stats?.totalCost)} />
      <StatItem title="Total consumption" value={toLocaleNumberString(stats?.totalConsumption)} />
      <StatItem title="Highest consumption" details={`${toShortDate(stats?.highestConsumption.from)}-${toShortDate(stats?.highestConsumption.to)}`} value={toLocaleNumberString(stats?.highestConsumption.consumption)} />
      <StatItem title="Lowest consumption" details={`${toShortDate(stats?.lowestConsumption.from)}-${toShortDate(stats?.lowestConsumption.to)}`} value={toLocaleNumberString(stats?.lowestConsumption.consumption)} />
      <StatItem title="Highest price" details={`${toShortDate(stats?.highestPrice.from)}-${toShortDate(stats?.highestPrice.to)}`} value={toLocaleNumberString(stats?.highestPrice.unitPrice)} />
      <StatItem title="Lowest price" details={`${toShortDate(stats?.lowestPrice.from)}-${toShortDate(stats?.lowestPrice.to)}`} value={toLocaleNumberString(stats?.lowestPrice.unitPrice)} />
      <StatItem title="Highets cost" details={`${toShortDate(stats?.highestPrice.from)}-${toShortDate(stats?.highestPrice.to)}`} value={toLocaleNumberString(stats?.highestPrice.cost)} />
      <StatItem title="Lowest cost" details={`${toShortDate(stats?.lowestPrice.from)}-${toShortDate(stats?.lowestPrice.to)}`} value={toLocaleNumberString(stats?.lowestPrice.cost)} />
      <StatItem title="Average price" value={toLocaleNumberString(stats?.averagePrice)} />
      <StatItem title="Average consumption" value={toLocaleNumberString(stats?.averageConsumption)} />
      <StatItem title="Average cost" value={toLocaleNumberString(stats?.averageCost)} />
      <StatItem title="Average consumption peak" details={stats?.averagePeaks.map(c => toShortDate(c.from)).join(", ")} value={toLocaleNumberString(stats?.averagePeakPrice)} />
    </CListGroup>
  );
}
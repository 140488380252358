import { CListGroup } from "@coreui/react";
import _ from "lodash";
import { useRecoilValue } from "recoil"
import { priceInfoState } from "../../features/priceinfo/state"
import { toLocaleNumberString } from "../../utils/formatters";
import { StatItem } from "../Shared/StatItem/StatItem";

export const PriceInfoStats = () => {
    const priceInfo = useRecoilValue(priceInfoState);

    const todayAverage = _.meanBy(priceInfo?.todayPrices ?? [], p => p.total);
    const tomorroAverage = _.meanBy(priceInfo?.tomorrowPrices ?? [], p => p.total);

    return (
        <CListGroup>
            <StatItem title="Average price today" value={toLocaleNumberString(todayAverage)} />
            {!isNaN(tomorroAverage) &&
                <StatItem title="Average price tomorrow" value={toLocaleNumberString(tomorroAverage)} />
            }
            
        </CListGroup>
    )
}
import { Supplier } from "../configuration/models"

export const supplier: Supplier = {
    name: "Elvia",
    treatWeekendAsNight: true,
    tariffs: [
        {
            stage: 1,
            rangeFrom: 0,
            rangeTo: 1.99,
            priceMonth: 125,
            priceDay: 43.10,
            priceNight: 36.85
        },
        {
            stage: 2,
            rangeFrom: 2,
            rangeTo: 4.99,
            priceMonth: 200,
            priceDay: 43.10,
            priceNight: 36.85
        },
        {
            stage: 3,
            rangeFrom: 5,
            rangeTo: 9.99,
            priceMonth: 325,
            priceDay: 43.10,
            priceNight: 36.85
        },
        {
            stage: 4,
            rangeFrom: 10,
            rangeTo: 14.99,
            priceMonth: 450,
            priceDay: 43.10,
            priceNight: 36.85
        },
        {
            stage: 5,
            rangeFrom: 15,
            rangeTo: 19.99,
            priceMonth: 575,
            priceDay: 43.10,
            priceNight: 36.85
        },
        {
            stage: 6,
            rangeFrom: 20,
            rangeTo: 24.99,
            priceMonth: 700,
            priceDay: 43.10,
            priceNight: 36.85
        },
        {
            stage: 7,
            rangeFrom: 25,
            rangeTo: 49.99,
            priceMonth: 1325,
            priceDay: 43.10,
            priceNight: 36.85
        },
        {
            stage: 8,
            rangeFrom: 50,
            rangeTo: 74.99,
            priceMonth: 1950,
            priceDay: 43.10,
            priceNight: 36.85
        },
        {
            stage: 9,
            rangeFrom: 75,
            rangeTo: 99.99,
            priceMonth: 2575,
            priceDay: 43.10,
            priceNight: 36.85
        },
        {
            stage: 10,
            rangeFrom: 100,
            rangeTo: 99999999999999999999,
            priceMonth: 5150,
            priceDay: 43.10,
            priceNight: 36.85
        }
    ]
}

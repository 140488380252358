import { CFormSelect } from "@coreui/react";
import { CFormSelectProps } from "@coreui/react/dist/components/form/CFormSelect";

export const ChartPropSelect: React.FC<CFormSelectProps> = (props) => {
  return (
    <CFormSelect
      {...props}
     >
      <option></option>
      <option value="consumption">Consumption</option>
      <option value="cost">Cost</option>
      <option value="unitPrice">Price</option>
    </CFormSelect>
  )
}
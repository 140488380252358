import { DatasetsInput } from "../components/DatasetQueries/DatasetsInput";
import { Card } from "../components/Shared/Card/Card";

export const Datasets = () => {
  return (
    <Card>
      <DatasetsInput />
    </Card>
  );
}

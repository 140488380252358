import React from 'react';
import ReactDOM from 'react-dom';
import '@coreui/coreui/dist/css/coreui.min.css';
import '@coreui/chartjs/dist/css/coreui-chartjs.min.css';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import dayjs from 'dayjs';
import 'dayjs/locale/nb';
import weekYear from 'dayjs/plugin/weekYear';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import { RecoilRoot } from 'recoil';
import { ErrorHandler } from './ErrorHandler';
import { BrowserRouter } from 'react-router-dom';

dayjs.locale('nb');
dayjs.extend(weekYear);
dayjs.extend(weekOfYear);
dayjs.extend(utc);
dayjs.extend(weekday);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ErrorHandler>
        <RecoilRoot>
          <App />
        </RecoilRoot>
      </ErrorHandler>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

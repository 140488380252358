import { CBadge, CListGroupItem } from "@coreui/react"
import { FC } from "react"

export const StatItem: FC<{title: string, details?: string, value: any}> = ({title, details, value}) => {
    return (
      <CListGroupItem className="d-flex justify-content-between align-items-center">
        <div>
          <h5>{title}</h5>
          {details &&
            <small>{details}</small>
          }
        </div>
        <CBadge color="info" shape="">{value}</CBadge>
      </CListGroupItem>
    )
  }

import { CButton, CCol, CFormInput, CFormLabel, CFormSelect, CInputGroup, CModal, CModalBody, CModalHeader } from "@coreui/react";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { configurationState, homesState, openSettingsState } from "../../features/configuration/state";
import { suppliers } from "../../features/supplier/state";

export const AccessTokenInput = () => {
  const [configuration, setConfiguration] = useRecoilState(configurationState);
  const [showSettings, setShowSettings] = useRecoilState(openSettingsState);
  const homes = useRecoilValue(homesState);

  const [localToken, setLocalToken] = useState(configuration.accessToken);

  const updateToken = () => {
    setConfiguration({accessToken: localToken});
  }

  useEffect(() => {
    if (configuration.accessToken === undefined || configuration.accessToken === '')
      setShowSettings(true);
  }, [configuration.accessToken, setShowSettings]);
  return (
    <div>
      <CModal visible={showSettings} onClose={() => setShowSettings(false)}>
        <CModalHeader>
          Please provide your Tibber Access Token
        </CModalHeader>
        <CModalBody>
          <p>This will only be used in this browser session.</p>
          <CButton onClick={(event) => setConfiguration({})}>Remove all config</CButton>
          <CCol>
            <CFormLabel htmlFor="accessTokeninput">Tibber access token</CFormLabel>
            <CInputGroup>
              <CFormInput id="accessTokeninput" type="text" defaultValue={localToken} onChange={(event) => setLocalToken(event.target.value)} />
              <CButton onClick={updateToken} color="secondary">Verify</CButton>
            </CInputGroup>
            
          </CCol>
          {homes.length > 0 &&
            <CCol>
              <CFormLabel>Select Home</CFormLabel>
              <CFormSelect value={configuration.homeId} onChange={(event) => setConfiguration({...configuration, homeId: event.target.value})}>
                <option value=""></option>
                {homes?.map((home, index) => {
                  return (
                    <option value={home.id} key={`home-${index}`}>{home.address.address1}</option>
                  )
                })}
              </CFormSelect>
            </CCol>
          }
          <CFormLabel>Supplier</CFormLabel>
          <CFormSelect value={configuration.supplier} onChange={(event) => setConfiguration({...configuration, supplier: event.target.value})}>
            <option value=""></option>
            {suppliers.map((supplier, index) => (
              <option value={supplier.name} key={`supplier-${index}`}>{supplier.name}</option>
            ))}
          </CFormSelect>
        </CModalBody>
      </CModal>
    </div>
  );
}
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { EnergyResolution } from "tibber-api/lib/src/models/enums/EnergyResolution";
import { Card } from "../components/Shared/Card/Card";
import { configurationState } from "../features/configuration/state";
import { datasetById } from "../features/datasets/state";
import { useTibberQueryClient } from "../hooks/useTibberClient";
import { consumption } from "../queries/consumption.gql";
import { BuildConsumptionQuery } from "../utils/queryBuilder";
import lodash from "lodash";
import { CChart } from "@coreui/react-chartjs";
import { CSpinner } from "@coreui/react";
import { IConsumption } from "tibber-api/lib/src/models/IConsumption";
import { getFromToDate } from "../utils/time";

export const DatasetDetails = () => {
  const { id } = useParams();
  const config = useRecoilValue(configurationState);
  const dataset = useRecoilValue(datasetById(id as string));
  const [queries, setQueries] = useState([...dataset.queries]);
  const [loaded, setLoaded] = useState(false);

  const tibberClient = useTibberQueryClient();

  const initializeData = async () => {
    for (let query of queries) {
      const dateRange = getFromToDate(query.dateRangePreset, query.dateFrom as Date, query.dateTo as Date);

      const tempQuery = { ...query };
      const result = await tibberClient?.query(consumption, BuildConsumptionQuery(config.homeId as string, dateRange?.dateFrom as Date, dateRange?.dateTo as Date, dataset?.resolution as EnergyResolution));
      if (result && result.viewer && result.viewer.home && result.viewer.home.consumption) {
        tempQuery.consumptions = [...result.viewer.home.consumption.nodes];
      } else {
        tempQuery.consumptions = []
      }

      const index = queries?.findIndex(q => q.id === tempQuery.id);
      queries[index] = tempQuery;

      setQueries([...queries]);
    }
  }

  useEffect(() => {
    initializeData()
      .then(() => setLoaded(true));
  }, [id]);

  return (
    <Card>
      <h1>{dataset?.name}</h1>
      {loaded &&
        <CChart
          type={dataset.chartType as any}
          data={{
            labels: lodash.orderBy(queries.map(c => c.consumptions), c => c?.length, "desc")[0]?.map((c, i) => i + 1),
            datasets: queries.map(q => {
              const [r,g,b] = q.color;
              return {
                type: dataset.chartType as any,
                label: q.name,
                data: q.consumptions?.map(c => {
                  const chartProp = q.chartProp as keyof IConsumption;
                  return (dataset.chartProp ? c[chartProp] : c.consumption)
                }) as number[],
                borderColor: `rgb(${r},${g},${b})`,
                borderWidth: 0.6,
                backgroundColor: `rgba(${r},${g},${b},0.6)`,
              }
            })
          }}
        />
      }
      {!loaded &&
        <CSpinner />
      }
    </Card>
  );
}

import { Supplier } from "../configuration/models"

export const supplier: Supplier = {
    name: "Norgesnett",
    treatWeekendAsNight: false,
    tariffs: [
        {
          stage: 1,
          rangeFrom: 0,
          rangeTo: 1.99,
          priceMonth: 129.94,
          priceDay: 48.43,
          priceNight: 38.8
        },
        {
          stage: 2,
          rangeFrom: 2,
          rangeTo: 4.99,
          priceMonth: 216.56,
          priceDay: 48.43,
          priceNight: 38.8
        },
        {
          stage: 3,
          rangeFrom: 5,
          rangeTo: 9.99,
          priceMonth: 356.13,
          priceDay: 48.43,
          priceNight: 38.8
        },
        {
          stage: 4,
          rangeFrom: 10,
          rangeTo: 14.199,
          priceMonth: 633.33,
          priceDay: 48.43,
          priceNight: 38.8
        },
        {
          stage: 5,
          rangeFrom: 15,
          rangeTo: 19.99,
          priceMonth: 841.23,
          priceDay: 48.43,
          priceNight: 38.8
        },
        {
          stage: 6,
          rangeFrom: 20,
          rangeTo: 24.99,
          priceMonth: 1043.35,
          priceDay: 48.43,
          priceNight: 38.8
        },
        {
          stage: 7,
          rangeFrom: 25,
          rangeTo: 49.99,
          priceMonth: 1617.00,
          priceDay: 48.43,
          priceNight: 38.8
        },
        {
          stage: 8,
          rangeFrom: 50,
          rangeTo: 74.99,
          priceMonth: 2531.38,
          priceDay: 48.43,
          priceNight: 38.8
        },
        {
          stage: 9,
          rangeFrom: 75,
          rangeTo: 99.99,
          priceMonth: 3445.75,
          priceDay: 48.43,
          priceNight: 38.8
        },
        {
          stage: 10,
          rangeFrom: 100,
          rangeTo: 1000000000000000,
          priceMonth: 5584.43,
          priceDay: 48.43,
          priceNight: 38.8
        }
    ]
}
